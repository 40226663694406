@import url(https://fonts.googleapis.com/css?family=Lato);
/*primereact spinner start*/
.p-progress-spinner {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto !important;
  height: initial !important;
  margin: auto;
}

/*primereact spinner end*/

.progress-spinner-backdrop {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F2F7F9;
  z-index: 1000;
}

.loading-heading {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  top: 40%;
  position: absolute;
  width: 100%;
  color: #212529;
}

.loading-sub {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  top: 45%;
  position: absolute;
  width: 100%;
  color: #212529;
}

.loading-spinner {
  position: absolute;
  width: 100%;
  max-width: 120px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  /* center */
  -webkit-animation: spinLoader 1000ms steps(12, end) infinite;
  animation: spinLoader 1000ms steps(12, end) infinite;
}

@-webkit-keyframes spinLoader {
  from {
    transform: translate(-50%, -50%) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

@keyframes spinLoader {
  from {
    transform: translate(-50%, -50%) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}
body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #585e62;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  overflow-x: hidden;
}

textarea {
  resize: none;
}

code {
  color: #585e62;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

body h1 {
  color: #585e62 !important;
  font-size: 25px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}
.h1-brand{
  color: #007EB1 !important;
}

body h2 {
  color: #585e62 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 30px;
}
.h2-mid{
  color: #585E62;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  max-width: 696px;
  display: inline-block;
}

body h3 {
  color: #585e62 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 20px;
}

body h4 {
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 18px;
}

body h5 {
  color: #007eb1;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 13px;
}

body h6 {
  color: #007eb1;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
}
hr {
  border: 1px solid #b7babc !important;
  background-color: #b7babc;
}
body p {
  color: #007eb1;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
}

.p1 {
  color: #007eb1;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.p2 {
  color: #007eb1;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
.para-lead{
  color: #585E62;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  max-width: 696px;
  display: inline-block;
}

.link-h {
  color: #007eb1;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.link-h1 {
  color: #007eb1;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.link-h1 {
  color: #007eb1;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

body a {
  color: #007ad9;
  text-decoration: underline !important;
  letter-spacing: 0;
  line-height: 24px;
}

body a:hover {
  color: #0a2d48 !important;
  text-decoration: underline !important;
  letter-spacing: 0;
  line-height: 24px;
}
.text-decoration-none{
  text-decoration: none !important;
}
.text-decoration-none:hover{
  color: #007ad9 !important;
}

.infoText {
  color: #212529;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
}
.required-field-label::after {
  content: " *";
  color: red;
}

.p-accordion-content {
  padding-top: 10px;
}

.overflow-hidden {
  overflow: hidden;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.auto-margin {
  margin: auto !important;
}
.p-noborder {
  border: none !important;
}

.text-underline {
  text-decoration: underline;
}

.text-align-sub {
  vertical-align: sub;
}

.visibility-hidden {
  visibility: hidden;
  display: none;
}

.p-float-right {
  float: right;
}

@media (max-width: 720px) {
  .p-dialog {
    width: 100%;
  }
}

@media (min-width: 720px) {
  .p-dialog {
    width: 50%;
  }
}

table {
  border-collapse: collapse !important;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.cursor-pointer {
  cursor: pointer;
}
.home-list-view-header {
  text-align: left !important;
  border: none !important;
  background-color: white !important;
  border-bottom: 2px solid grey !important;
}
.home-list-view-body {
  text-align: left !important;
  background-color: white !important;
  border: none !important;
  border-bottom: 1px solid grey !important;
  word-break: break-word;
}
.report-list-view-header {
  text-align: left !important;
  box-sizing: border-box !important;
  border: 1px solid #b7babc !important;
  background-color: #f6f6f6 !important;
}
.report-list-view-header-button {
  box-sizing: border-box !important;
  border: none !important;
  background-color: #ffffff !important;
  overflow: hidden;
  width: 110px;
}
.report-list-view-header-button-trash {
  box-sizing: border-box !important;
  border: none !important;
  background-color: #ffffff !important;
  overflow: hidden;
  width: 10px;
}
.report-list-view-header-expander {
  box-sizing: border-box !important;
  border: 1px solid #b7babc !important;
  background-color: #f6f6f6 !important;
  overflow: hidden;
  width: 55px;
}
.report-list-view-body {
  text-align: left !important;
  box-sizing: border-box !important;
  border: 1px solid #b7babc !important;
  background-color: #ffffff !important;
  word-break: break-word;
}
.report-list-view-body-button {
  box-sizing: border-box !important;
  border: none !important;
  background-color: #ffffff !important;
  overflow: hidden;
  width: 13%;
  padding-right: 0px !important;
}
.report-list-view-body-expander {
  text-align: center !important;
  box-sizing: border-box !important;
  border: 1px solid #b7babc !important;
  background-color: #ffffff !important;
}
.view-report-width {
  width: 13%;
}

.p-sortable-column.p-highlight,
.p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #007ad9 !important;
}

body .p-paginator {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white !important;
  border: none !important;
  float: left !important;
}

body .p-paginator .p-dropdown {
  min-width: 5em !important;
}

body .p-paginator-pages .p-link {
  min-width: 5em !important;
}

.content-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.content-wrapper .content-section {
  margin: 1em 0px;
  padding: 1em;
  border: 1px #B7BABC solid;
}

.content-wrapper .confirm-wrapper h1.h1-brand{
  color: #007EB1 !important;
}
.content-wrapper h3 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}
.home-text {
  color: #585e62;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.content-wrapper h2.text-bold {
  font-weight: bold !important;
}
.app-content-lg {
  margin-bottom: 15% !important;
}

.app-content h2 {
  margin: 5px;
}

@media (max-width: 720px) {
  .app-content {
    margin-bottom: 50%;
  }

  .app-content-lg {
    margin-bottom: 75% !important;
  }
}

.app-content-wrapper {
  min-height: calc(100vh - 50px);
}

.p-button-save {
  color: #c15001;
}

.p-system-notify {
  color: #c15001;
  font-size: 1em !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #007ad9;
}

.link-button:hover,
.link-button:focus {
  font-weight: bold;
  font-size: medium;
}

.fieldset-label {
  border-color: #dadada;
  border-style: solid;
  border-width: 0.1em;
}

.fieldset-label legend {
  font-weight: bold;
}

.p-accordion-content > .p-grid {
  padding: 7px 0 0 0;
}

.p-accordion > .p-accordion-tab {
  padding: 0 0 5px 0;
}
.pl-5 {
  padding-left: 5px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.p-button .pr-0 .p-button-text {
  padding: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}

.mt-60 {
  margin-top: 60px;
}
.mtb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}

.home-wrapper {
  padding-top: 40px !important;
}

.confirm-wrapper {
  padding-top: 100px !important;
  text-align: center;
  word-break: break-word;
}

body .p-button {
  white-space: nowrap;
  border-radius: 4px !important;
}

.p-calendar-button {
  background-color: #007eb1 !important;
  border-color: #007eb1 !important;
}

.p-button-text {
  white-space: normal;
}

.p-button .p-button-text {
  line-height: 28px !important;
}
.report-list-view-body-button .p-button .p-button-text {
  line-height: 28px !important;
  padding: 0 !important;
}

.p-button-link {
  background: none !important;
  text-decoration: none !important;
  border: none !important;
  padding-top: 5px !important;
  padding-left: 0 !important;
  color: #007eb1 !important;
  cursor: pointer;
  box-shadow: 0 !important;
  text-align: left !important;
  text-align: initial !important;
}

.p-button-link:hover {
  background: none !important;
  text-decoration: none !important;
  border: none !important;
  padding-top: 5px !important;
  padding-left: 0 !important;
  color: #007eb1 !important;
  box-shadow: 0 !important;
}

 .p-button-link .p-button-label {
  color: #007eb1 !important;
  text-decoration: none !important;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 28px !important;
  font-weight: 600 !important;
  text-align: left !important;
  text-align: initial !important;

}

.p-button-link:hover .p-button-text {
  color: #0a2d48 !important;
  text-decoration: none !important;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 600;
  text-align: left !important;
  text-align: initial !important;
}

.p-button-link:hover .p-button-label  {
  color: #0a2d48 !important;
  text-decoration: none !important;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 600;
  text-align: left !important;
  text-align: initial !important;
}

.p-button-link:enabled:active {
  background: none !important;
  color: #007eb1 !important;
  border: none !important;
}

body .p-button-link:enabled:active {
  background: none !important;
  color: #007eb1 !important;
  border: none !important;
}

body .p-button-link:enabled:focus {
  background: none !important;
  color: #007eb1 !important;
  border: none !important;
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

.p-button-primary {
  border-radius: 4px !important;
  background-color: #9ac02c !important;
  color: #212529 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: center !important;
  border: none !important;
}

.p-button-primary:hover {
  border-radius: 4px !important;
  background-color: #add33f !important;
  color: #212529 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: none !important;
}

body .p-button-primary:enabled:active {
  background-color: #9ac02c !important;
  color: #ffffff !important;
  border-color: #c3d49b !important;
}

body .p-button-primary:enabled:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  background-color: #9ac02c !important;
  box-shadow: 0 0 0 0.2em #c3d49b !important;
}

/*Button Secondary*/
.p-button-secondary {
  border-radius: 4px !important;
  background-color: #007eb1 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: center !important;
  border: none !important;
}

.p-button-secondary:hover {
  border-radius: 4px;
  background-color: #01628b !important;
}

body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
  border-radius: 0 4px 4px 0 !important;
}

body .p-button-secondary:enabled:focus {
  background-color: #007eb1 !important;
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2em #d6e9f2 !important;
}

body .p-button-secondary:enabled:active {
  background-color: #01628b !important;
  color: #ffffff !important;
  border-color: #d6e9f2 !important;
}

/*Button Danger*/

.p-button-danger {
  border-radius: 4px !important;
  background-color: #b90724 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: center !important;
  border: none !important;
}

.p-button-danger :hover {
  border-radius: 4px;
  background-color: #cc0033 !important;
}

body .p-button-danger:enabled:focus {
  background-color: #b90724 !important;
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2em #fdd5e3 !important;
}

body .p-button-danger:enabled:active {
  background-color: #cc0033 !important;
  color: #ffffff !important;
  border-color: #fdd5e3 !important;
}

/*Button Clear*/
.p-button-clear {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  color: #007EB1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: center !important;
  border: 0px solid #027eb1 !important;
}
.p-button-clear :hover {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  color: #007EB1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: none !important;
}
body .p-button-clear:enabled:focus {
  background-color: #ffffff !important;
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}
body .p-button-clear:enabled:active {
  background-color: #ffffff !important;
  color: #007EB1 !important;
  border-color: #027eb1 !important;
}
/*Button Misc
*/
.p-button-misc {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  color: #212529 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: center !important;
  border: 1px solid #027eb1 !important;
}
.p-button-misc :hover {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  color: #212529 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: none !important;
}
body .p-button-misc:enabled:focus {
  background-color: #ffffff !important;
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}
body .p-button-misc:enabled:active {
  background-color: #ffffff !important;
  color: #212529 !important;
  border-color: #027eb1 !important;
}
/*Button Default
*/
.p-button-default {
  border-radius: 4px !important;
  background-color: #585e62 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: center !important;
  border: none !important;
}
.p-button-default :hover {
  border-radius: 4px !important;
  background-color: #464b4e !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: none !important;
}
/*Button Misc
*/
.p-button-misc {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  color: #212529 !important;
  font-size: 16px !important;
  font-weight: 600;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: center !important;
  border: 1px solid #007eb1;
}
.p-button-misc:hover {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  color: #212529 !important;
  font-size: 16px !important;
  font-weight: 600;
  letter-spacing: 0 !important;
  text-align: center !important;
  border: 1px solid #007eb1;
}

/*Body default*/
body .p-button-default:enabled:focus {
  background-color: #585e62 !important;
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2em #b7babc !important;
}
body .p-button-default:enabled:active {
  background-color: #464b4e !important;
  color: #ffffff !important;
  border-color: #b7babc !important;
}

body .p-messages.p-messages-info {
  background-color: #eff5f6;
  border-top: 4px solid #1f76aa !important;
  color: #212529;
}
body .p-messages.p-messages-success {
  background-color: #b7d8b7;
  border-top: 4px solid #9ebe6d !important;
  color: #212529;
}

body .p-messages.p-messages-warn {
  background-color: #ffedde;
  border-top: 4px solid #f9af71 !important;
  color: #212529;
}
body .p-messages.p-messages-error {
  background-color: #efdde0;
  border-top: 4px solid #b90724 !important;
  color: #212529;
}
body .p-message.p-component.p-message-error {
  /* background-color: transparent !important; */
  color: #212529 !important;
}

body .p-message-error {
  /* background-color: transparent !important; */
  color: #212529 !important;
}
.p-field-error .p-message-text{
  color: #b90724 !important;
}


/* changing from none to flex to fix ticket 1927 - restyling warning banner */
body .p-message.p-component.p-message-error .p-message-icon {
  display: flex;
  margin-left: 10px !important;
}
.content-hide {
  display: none;
}
.form-header h1 {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

.report-view-left-pane {
  background-color: #f6f6f6 !important;
  margin: 0px !important;
}

.report-view-left-pane .report-view-value {
  padding-left: 1em !important;
}

.report-view-label {
  font-weight: bold !important;
  padding-left: 1em !important;
}

.report-view-value {
  word-break: break-word;
}

.report-view-report-title {
  word-break: break-word;
}

.report-view-sub-label {
  font-weight: bold !important;
}

.report-view-sub-label-value {
  padding-right: 0.3em !important;
  word-break: break-word;
}

.with-border {
  border: 1px solid grey !important;
  margin-left: 0px !important;
  margin-top: 5px !important;
}
.document {
  font-size: 2em !important;
}
.step-header {
  padding: 1em !important;
}
.step-body {
  padding: 1em !important;
}

.with-border .pi {
  font-size: 1.5em;
  vertical-align: middle;
}
.step-container .pi {
  font-size: 1.5em;
  vertical-align: middle;
}
.confirm-button-nav{
  display: inline-block;
  text-align: center;
 }
 .pi-trash {
  cursor: pointer !important;
}

.content-section .pi-trash {
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}


/* restyling warning banner ticket 1927 prime react upgrade */
.p-message-wrapper {
  /* background-color: #efdde0; */
  padding: 5px !important;
  color: black;
  font-weight: bold;
  display: flex;
  width: 95%;  
  margin-left: 40px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
}

.p-message-error .p-message-wrapper .p-message-close {
  font-weight: bold;
  margin-bottom: 20px;
  transform:translate(70%,-80%);
  color: black;
  border-radius: 100%;
  background-color: #f8b7bd !important;
  height: 20px !important;
  width: 20px !important;
  padding: 4px !important;
}

.p-message-success .p-message-wrapper .p-message-close {
  font-weight: bold;
  margin-bottom: 20px;
  transform:translate(70%,-80%);
  color: black;
  border-radius: 100%;
  background-color: #9ebe6d !important;
  height: 20px !important;
  width: 20px !important;
  padding: 4px !important;
}
.p-inputgroup .p-component {
  width: 100% !important;
}

.p-inputgroup .p-inputgroup-addon {
  text-align: center !important;
}

.p-message.p-message-info{
  background-color: #EFF5F6 !important;
  border-top: 4px solid #1f76aa !important;
  color: #212529 !important;
}

.p-message.p-message-success {
  background-color: #F2F7EA !important;
  font-family: "Lato", sans-serif !important;
  border-top: 4px solid #9EBE6D !important;
  color: #212529 !important;
}

.p-message.p-message-warn{
  background-color: #ffedde !important;
  border-top: 4px solid #f9af71 !important;
  color: #212529 !important;
}

.p-message.p-message-error{
  background-color: #EFDDE0 !important;
  border-top: 4px solid #b90724 !important;
  color: #212529 !important;
}
.p-message.p-message-success .p-message-close{
  background-color: #F2F7EA;
}
.p-message.p-message-error .p-message-close {
  background-color: #EFDDE0;
}
.p-message.p-message-info .p-message-close {
  background-color: #EFF5F6;
}
.p-message.p-message-warn .p-message-close {
  background-color: #FFEDDE;
}

