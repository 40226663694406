/*primereact spinner start*/
.p-progress-spinner {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: initial !important;
  margin: auto;
}

/*primereact spinner end*/

.progress-spinner-backdrop {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F2F7F9;
  z-index: 1000;
}

.loading-heading {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  top: 40%;
  position: absolute;
  width: 100%;
  color: #212529;
}

.loading-sub {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  top: 45%;
  position: absolute;
  width: 100%;
  color: #212529;
}

.loading-spinner {
  position: absolute;
  width: 100%;
  max-width: 120px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  /* center */
  -webkit-animation: spinLoader 1000ms steps(12, end) infinite;
  animation: spinLoader 1000ms steps(12, end) infinite;
}

@-webkit-keyframes spinLoader {
  from {
    transform: translate(-50%, -50%) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

@keyframes spinLoader {
  from {
    transform: translate(-50%, -50%) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}